<script setup lang="ts">
import { useWorkflowStore } from '~/stores/workflow';
import type { FormSubmitEvent } from '#ui/types';
import { z } from 'zod';
import clone from 'clone';

const { $supabaseClient, $toast } = useNuxtApp();
const workflowStore = useWorkflowStore();

const currentWorkflowStatus = computed(() => {
  return workflowStore.workflow.status;
})

async function updateWorkflowStatus() {
  const newStatus = currentWorkflowStatus.value === 'draft' ? 'published' : 'draft'
  console.log("workflow status", newStatus)
  try {
    const { error } = await $supabaseClient
      .from('workflows')
      .update({
        status: newStatus
      })
      .eq('id', workflowStore.workflow.id)
    if (error) throw error;
    workflowStore.workflow.status = newStatus;
    $toast.success(newStatus === 'published' ? `Workflow is live now` : 'Workflow status successfully changed to Draft')
  } catch (error) {
    $toast.error('Failed to update workflow status. Please try again or contact support')
    throw error;
  }
}

const items = computed(() => ([
  [
    {
      ...(currentWorkflowStatus.value === 'draft' ? {
        label: 'Publish',
        icon: 'i-lucide-circle-check-big',
      } : {
        label: 'Unpublish',
        icon: 'i-lucide-circle-x',
      }),
      click: () => updateWorkflowStatus(),
    },
  ],
  [
    {
      label: 'Exit',
      icon: 'i-lucide-log-out',
      click: () => {
        window.location.href = '/workflows'
      }
    },
  ],
]))

const isEditingName = ref(false);
const schema = z.object({
  name: z.string().min(1, 'Name cannot be empty').max(100, 'Name cannot more than 100 characters')
});

type NameSchema = z.output<typeof schema>;

const state = reactive({
  name: workflowStore.workflow?.name ?? 'Untitled',
});

watch(() => workflowStore.workflow?.name, (newValue) => {
  state.name = newValue
})

function cancelEditingName() {
  isEditingName.value = false;
  state.name = workflowStore.workflow.name;
}

function handleNameSubmit(event: FormSubmitEvent<NameSchema>) {
  workflowStore.workflow.name = event.data.name;
  isEditingName.value = false;
}

// Handle keyboard events
function handleNameKeydown(event: KeyboardEvent) {
  if (event.key === 'Enter') {
    event.preventDefault();
    const form = document.getElementById('name-form') as HTMLFormElement;
    form?.requestSubmit();
  } else if (event.key === 'Escape') {
    cancelEditingName();
  }
}

const isSaving = ref(false);
const { transformToDbFormat } = useNodeTransformation();

async function updateWorkflowState() {
  try {
    isSaving.value = true;
    
    // Transform the workflow data to DB format
    const dbData = transformToDbFormat(clone({
      workflow: workflowStore.workflow,
      nodes: workflowStore.nodes,
      edges: workflowStore.edges,
      findNode: workflowStore.findNode
    }));
    
    // Call the modular workflow update function
    const { data, error } = await $supabaseClient.rpc('update_workflow', {
      p_workflow: dbData.workflow,
      p_workflow_nodes: dbData.workflow_nodes,
      p_workflow_trigger_conditions: dbData.workflow_trigger_conditions,
      p_workflow_steps: dbData.workflow_steps,
      p_workflow_interactive_options: dbData.workflow_interactive_options,
      p_workflow_edges: dbData.workflow_edges
    });
    
    if (error) throw error;
    
    if (!data.success) {
      $toast.error('Failed to update workflow. Please try again or contact support')
      throw new Error(`Database error: ${data.error}`);
    }
    $toast.success('Workflow updated successfully')
  } catch (error) {
    console.error('Error saving workflow:', error);
  } finally {
    isSaving.value = false;
  }
}
</script>

<template>
  <div class="w-screen h-screen">
    <div class="h-16 bg-white border-b border-gray-200 flex items-center px-4 gap-4">
      <NuxtButton
        to="/workflows"
        color="white"
        variant="solid"
        size="sm"
        square
        external
        icon="i-lucide-chevron-left"
      />
      
      <div 
        v-if="!isEditingName" 
        class="flex items-center gap-2 cursor-pointer hover:bg-gray-100 px-2 py-1 rounded-lg"
        @click="isEditingName = true" 
      >
        <p class="text-lg font-medium">
          {{ state.name }}
        </p>
        <NuxtIcon v-if="state?.name" name="i-lucide-pencil" class="w-3.5 h-3.5" />
      </div>
      
      <NuxtForm
        v-else
        id="name-form"
        :schema="schema"
        :state="state"
        class="flex items-center gap-2"
        @submit="handleNameSubmit"
      >
        <NuxtFormGroup
          label=""
          name="name"
          eager-validation
        >
          <NuxtInput
            v-model="state.name"
            name="name"
            class="text-lg font-medium"
            :ui="{ 
              base: 'min-w-[200px]',
              padding: { sm: 'px-2 py-1' },
              size: 'sm'
            }"
            size="sm"
            autofocus
            @keydown="handleNameKeydown"
          />
        </NuxtFormGroup>
        <div class="flex gap-1">
          <NuxtButton
            type="submit"
            color="primary"
            variant="ghost"
            size="xs"
            icon="i-lucide-check"
            square
          />
          <NuxtButton
            type="button"
            color="gray"
            variant="ghost"
            size="xs"
            icon="i-lucide-x"
            square
            @click="cancelEditingName"
          />
        </div>
      </NuxtForm>
      
      <div class="flex-1 flex justify-end items-center gap-4">
        <NuxtBadge 
          v-if="currentWorkflowStatus"
          variant="subtle"
          :color="currentWorkflowStatus === 'draft' ? 'gray' : 'green'"
          size="sm"
          :ui="{
            base: 'capitalize',
            rounded: 'rounded-full'
          }"
        >
          {{ currentWorkflowStatus }}
        </NuxtBadge>
        <NuxtButtonGroup 
          size="md" 
          orientation="horizontal"
          :ui="{
            wrapper: {
              horizontal: 'space-x-[1px]'
            }
          }"  
        >
          <NuxtButton
            color="primary"
            variant="solid"
            size="md"
            :loading="isSaving"
            :ui="{
              padding: {
                md: 'px-8'
              }
            }"
            @click.stop="updateWorkflowState"
          >
            {{ isSaving ? 'Saving' : 'Save' }}
          </NuxtButton>
          <NuxtDropdown 
            :items="items" 
            :popper="{ placement: 'bottom-start' }"
            :ui="{
              item: {
                base: 'hover:text-primary gap-2',
                icon: {
                  active: 'group-hover:text-primary'
                }
              }
            }"  
          >            
            <NuxtButton 
              icon="i-lucide-chevron-down" 
              color="primary" 
            />
          </NuxtDropdown>
        </NuxtButtonGroup>
      </div>
    </div>
    <div class="relative flex flex-1" style="height: calc(100vh - 64px);">
      <slot />
      <div class="absolute top-5 right-5">
        <NuxtButton 
          icon="i-lucide-plus"
          color="primary"
          variant="soft"
          size="xl"
          :ui="{
            rounded: 'rounded-full'
          }"
          @click="workflowStore.toggleSettingPanel({
            isOpen: true,
            type: 'add-node',
          })"
        />
      </div>
    </div>
  </div>
</template>